import type { FunctionComponent } from "react";
import type { FragmentRefs } from "relay-runtime";

import type { VariantProps } from "scmp-app/components/section/section-top/variant/types";

import {
  HeroContentsContainer,
  HeroRest,
  StyledContentItemHomeSecondary,
  StyledContentItemSectionTopLeadPrimary,
} from "./styles";

type Props = VariantProps & {
  currentTopicReference?: string;
  hasSponsor?: boolean;
  heroContentsGroup: {
    readonly " $fragmentSpreads": FragmentRefs<
      | "homeSecondaryContentItemContent"
      | "sectionTopLeadPrimaryContentItemContent"
      | "topStoriesItemContent"
    >;
  }[];
  withLiveTag?: boolean;
};

export const TopicHeroContents: FunctionComponent<Props> = ({
  currentTopicReference,
  hasSponsor,
  heroContentsGroup: [heroArticleLeading, heroOne, heroTwo],
  withLiveTag,
}) => (
  <HeroContentsContainer>
    {heroArticleLeading && (
      <StyledContentItemSectionTopLeadPrimary
        currentTopicReference={currentTopicReference}
        preferTopicLabel
        reference={heroArticleLeading}
        topicLinkVariant={{ type: "secondary" }}
        withComment={true}
        withLiveTag={withLiveTag ?? true}
        withTopic={hasSponsor ? false : true}
      >
        <HeroRest>
          {heroOne && (
            <StyledContentItemHomeSecondary
              currentTopicReference={currentTopicReference}
              reference={heroOne}
              skipHighlight={true}
              topicLinkVariant={{ type: "secondary" }}
              withComment={true}
              withLiveTag={withLiveTag ?? true}
              withTopic={hasSponsor ? false : true}
            />
          )}
          {heroTwo && (
            <StyledContentItemHomeSecondary
              currentTopicReference={currentTopicReference}
              reference={heroTwo}
              skipHighlight={true}
              topicLinkVariant={{ type: "secondary" }}
              withComment={true}
              withLiveTag={withLiveTag ?? true}
              withTopic={hasSponsor ? false : true}
            />
          )}
        </HeroRest>
      </StyledContentItemSectionTopLeadPrimary>
    )}
  </HeroContentsContainer>
);

TopicHeroContents.displayName = "TopicHeroContents";
