/**
 * @generated SignedSource<<2b427383fa7973af5f174014fb23d7ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksTopic$data = {
  readonly sponsor: {
    readonly __typename: "Sponsor";
  } | null | undefined;
  readonly types: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly " $fragmentType": "hooksTopic";
};
export type hooksTopic$key = {
  readonly " $data"?: hooksTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksTopic">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksTopic"
};

(node as any).hash = "2cdec1ab9d8ee12daad688f76ef148b2";

export default node;
