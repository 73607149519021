/**
 * @generated SignedSource<<1e3998d2c25b3e87e4b10dc5c9503719>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topicHeaderRelatedLinksTopic$data = {
  readonly relatedLink: ReadonlyArray<{
    readonly title: string;
    readonly url: string;
  } | null | undefined> | null | undefined;
  readonly relatedTopics: ReadonlyArray<{
    readonly name: string;
    readonly urlAlias: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"hooksTopic">;
  readonly " $fragmentType": "topicHeaderRelatedLinksTopic";
};
export type topicHeaderRelatedLinksTopic$key = {
  readonly " $data"?: topicHeaderRelatedLinksTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"topicHeaderRelatedLinksTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "topicHeaderRelatedLinksTopic",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "hooksTopic",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "types",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Sponsor",
          "kind": "LinkedField",
          "name": "sponsor",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TopicRelatedLink",
      "kind": "LinkedField",
      "name": "relatedLink",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "relatedTopics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "91e3e2163793c5ac8ca22bd8e1f28d0a";

export default node;
