import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { Container as AdsContainer } from "scmp-app/components/advertisement/ad-slots/ad-slot/styles";
import { SponsorHeadline } from "scmp-app/components/content/content-headline/styles";
import {
  ActionBar as PrimaryActionBar,
  Container as PrimaryContainer,
  CoverImage as PrimaryCoverImage,
  Headline as PrimaryHeadline,
} from "scmp-app/components/content/content-item-render/variants/home-primary/styles";
import { Headline as SecondaryHeadline } from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";
import { TopStoriesItem } from "scmp-app/components/top-stories-block/top-stories-item";

export const StyledTopStoriesItem = styled(TopStoriesItem)`
  margin-block-end: 20px;
  padding-block-end: 20px;
  border-block-end: 1px solid rgba(0, 0, 0, 0.1);

  ${theme.breakpoints.up("tablet")} {
    :last-child {
      margin-block-end: 0;
      padding-block-end: 0;
      border-block-end: none;
    }
  }
`;

export const TopContentsContainer = styled.div`
  display: grid;
  grid:
    "left" min-content
    "right" min-content
    / 100%;

  padding-block-end: 20px;

  ${theme.breakpoints.up("tablet")} {
    grid:
      "left right" min-content
      / 1fr calc((100% - 32px) / 3);
    gap: 32px;

    padding-block-end: 32px;
  }
`;

export const TopContentsLeftContainer = styled.div`
  grid-area: left;

  ${theme.breakpoints.up("tablet")} {
    ${PrimaryHeadline} {
      font-size: 22px;
    }
  }

  ${StyledTopStoriesItem}:first-child {
    ${PrimaryContainer} {
      display: flex;
      flex-direction: column-reverse;
    }
    ${PrimaryCoverImage} {
      margin-block-end: 20px;
    }
    ${PrimaryActionBar} {
      ${theme.breakpoints.up("tablet")} {
        margin-block-end: 0;
      }
    }
  }
`;

export const TopContentsRightContainer = styled.div`
  grid-area: right;

  ${StyledTopStoriesItem}:last-child {
    margin-block-end: 0;
    padding-block-end: 0;
    border-block-end: none;
  }

  ${theme.breakpoints.up("tablet")} {
    ${SecondaryHeadline} {
      font-size: 15px;
      line-height: 140%;
    }
    ${SponsorHeadline} {
      font-size: 16px;
      line-height: 140%;
    }
  }
  ${theme.breakpoints.up("desktop")} {
    max-inline-size: 268px;
  }
`;

export const AdsSlotContainer = styled.div`
  ${AdsContainer} {
    block-size: auto;
  }
`;
