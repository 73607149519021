import styled from "@emotion/styled";
import { fontNotoSansSC, fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";
import { EntityFollowButton } from "scmp-app/components/entity-follow-button";
import { SponsorTag } from "scmp-app/components/sponsor-tag";

import { TopicHeaderRelatedLinks } from "./topic-header-related-links";

export const Container = styled.div`
  border-block-end: 1px solid #0000001a;
`;

type WrapperProps = {
  $hasSponsor: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: ${props => (props.$hasSponsor ? "column" : "row")};
  gap: 20px;

  margin-block-end: 16px;
  ${theme.breakpoints.between("tablet", "desktop")} {
    gap: 16px;
  }

  ${theme.breakpoints.up("desktop")} {
    flex-direction: row;
    align-items: center;

    margin-block-end: 20px;
  }
`;

export const ImageIcon = styled(BaseImage)`
  inline-size: 64px;
  block-size: 64px;

  border: 1px solid #dadada;
  border-radius: 50%;

  object-fit: cover;
  object-position: 50%;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 96px;
    block-size: 96px;
  }
  ${theme.breakpoints.up("desktop")} {
    inline-size: 120px;
    block-size: 120px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

type LabelContainerProps = {
  $isGeneralTopic: boolean;
};

export const LabelContainer = styled.div<LabelContainerProps>`
  display: flex;
  align-items: center;

  margin-block-end: 4px;
  ${theme.breakpoints.up("desktop")} {
    margin-block-end: ${props => (props.$isGeneralTopic ? "8px" : "0")};
  }
`;

export const Label = styled.div`
  color: #a1a1a1;
  font-weight: 700;
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: 18.75px;
`;

export const TypeLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: 18.75px;
  text-transform: uppercase;
  span {
    color: #a1a1a1;
  }
`;

export const TopicContainer = styled.div`
  display: flex;
  gap: 16px;

  ${theme.breakpoints.up("mobile")} {
    justify-content: space-between;
  }
  ${theme.breakpoints.up("tablet")} {
    justify-content: start;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: baseline;

  ${theme.breakpoints.down("tablet")} {
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  gap: 4px;
  align-items: center;

  margin: 0;

  font-weight: 700;
  font-family: ${fontRobotoCondensed};
  text-transform: uppercase;

  ${theme.breakpoints.up("mobile")} {
    font-size: 24px;
    line-height: 28.13px;
  }

  ${theme.breakpoints.up("tablet")} {
    flex: initial;

    font-size: 32px;
    line-height: 37.5px;
  }

  ${theme.breakpoints.up("desktop")} {
    font-size: 48px;
    line-height: 56.25px;
  }
`;

export const ChineseTitle = styled.h1`
  font-weight: 400;
  font-size: 24px;
  font-family: ${fontNotoSansSC};
  line-height: 34.75px;
  text-transform: uppercase;

  ${theme.breakpoints.up("mobile")} {
    font-size: 16px;
    line-height: 23.17px;
  }

  ${theme.breakpoints.up("tablet")} {
    align-self: center;

    font-weight: 400;
    font-size: 20px;
    line-height: 28.96px;
    text-align: start;
  }

  ${theme.breakpoints.up("desktop")} {
    font-size: 24px;
    line-height: 62.75px;
  }
`;

export const StyledEntityFollowButton = styled(EntityFollowButton)`
  white-space: nowrap;
  button {
    inline-size: auto;
    padding-block: 4px;
    padding-inline: 8px;

    font-weight: 400;
    font-size: 14px;
    font-family: ${fontRobotoCondensed};
    line-height: 19.6px;
  }
`;

export const StyledRelatedLinks = styled(TopicHeaderRelatedLinks)``;

export const StyledSponsorTag = styled(SponsorTag)`
  flex-direction: row;
  gap: 4px;

  inline-size: fit-content;
  padding: 8px;

  font-size: 13px;
  line-height: 16.9px;

  border: 1px solid #d7d7d7;

  ${theme.breakpoints.up("tablet")} {
    font-size: 14px;
    line-height: 18.2px;
  }

  ${theme.breakpoints.up("desktop")} {
    flex-direction: column;
    gap: 0;
    align-self: end;
  }
`;
