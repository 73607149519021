import { NoSsr } from "@mui/base";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { getUrlPathWithoutQuery, isValidUrl } from "shared/lib/utils";

import { useTopicType } from "scmp-app/components/topic/hooks";
import type { topicHeaderRelatedLinksTopic$key } from "scmp-app/queries/__generated__/topicHeaderRelatedLinksTopic.graphql";

import {
  Container,
  Label,
  StyledBaseLink,
  StyledDot,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
} from "./styles";

type Props = {
  className?: string;
  reference: topicHeaderRelatedLinksTopic$key;
};

export const TopicHeaderRelatedLinks: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const topic = useFragment(
    graphql`
      fragment topicHeaderRelatedLinksTopic on Topic {
        ...hooksTopic
        relatedLink {
          title
          url
        }
        relatedTopics {
          name
          urlAlias
        }
      }
    `,
    reference_,
  );

  const { isSuperTopic = false } = useTopicType(topic);

  const { asPath } = useRouter();
  const renderRelatedLinks = () =>
    topic?.relatedLink?.map((link, index) => {
      const url = link?.url ?? "/";
      const pathname = isValidUrl(url) ? new URL(url).pathname : link?.url;
      const isSelected = pathname === getUrlPathWithoutQuery(asPath);
      return link?.url ? (
        <StyledSwiperSlide $isSuperTopic={isSuperTopic} key={index}>
          <NoSsr>
            <StyledBaseLink $isSelected={isSelected} pathname={link?.url}>
              {link?.title}
            </StyledBaseLink>
            {isSuperTopic && index !== (topic?.relatedLink?.length ?? 0) - 1 ? <StyledDot /> : null}
          </NoSsr>
        </StyledSwiperSlide>
      ) : null;
    });

  const renderRelatedTopics = () => {
    const relatedTopics = topic?.relatedTopics ?? [];
    return relatedTopics?.map((topic, index) => {
      const isSelected = topic?.urlAlias === getUrlPathWithoutQuery(asPath);
      return topic?.urlAlias ? (
        <StyledSwiperSlide $isSuperTopic={isSuperTopic} key={index}>
          <NoSsr>
            <StyledBaseLink $isSelected={isSelected} pathname={topic.urlAlias}>
              {topic?.name}
            </StyledBaseLink>
          </NoSsr>
        </StyledSwiperSlide>
      ) : null;
    });
  };

  if (
    (isSuperTopic && (topic?.relatedLink ?? []).length === 0) ||
    (!isSuperTopic && (topic?.relatedTopics ?? []).length === 0)
  )
    return null;

  return (
    <Container className={className}>
      <SwiperContainer>
        <StyledSwiper cssMode={false} pagination={false} slidesPerView="auto">
          {!isSuperTopic && (
            <StyledSwiperSlide $isSuperTopic={isSuperTopic} key="0">
              <Label>Related Topics:</Label>
            </StyledSwiperSlide>
          )}
          {isSuperTopic ? renderRelatedLinks() : renderRelatedTopics()}
        </StyledSwiper>
      </SwiperContainer>
    </Container>
  );
};

TopicHeaderRelatedLinks.displayName = "TopicHeaderRelatedLinks";
